import { Title } from '@solidjs/meta';
import {
	Heading,
	Container,
	Page,
	BreadcrumbItem,
	Section,
	Callout,
	Button,
	Link,
	HorizontalRule,
	Picture,
} from '@troon/ui';
import { createMemo, For, Show, Suspense } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { createAsync, useLocation } from '@solidjs/router';
import dayjs from '@troon/dayjs';
import { Icon } from '@troon/icons';
import { useIsEnabled } from '@troon/analytics';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { createContentfulListRequest } from '../../api/content/[model]/_client';
import { PictureSection } from '../../../components/layouts/picture-section';
import { RichText } from '../../../components/rich-text';
import type { RouteDefinition } from '@solidjs/router';

const tz = 'America/Phoenix';
export default function DEIPage() {
	const loc = useLocation();

	const themes = createAsync(
		async () => {
			const themes = await getThemes({});
			return themes.sort((a, b) =>
				dayjs(`${a.fields.startMonth} ${a.fields.startDay}, 2024`).isAfter(
					dayjs(`${b.fields.startMonth} ${b.fields.startDay}, 2024`),
				)
					? 1
					: -1,
			);
		},
		{ deferStream: true },
	);
	const theme = createMemo(() => {
		const today = dayjs.tz(new Date(), tz);
		return themes()?.find((theme) => {
			const start = dayjs.tz(`${theme.fields.startMonth} ${theme.fields.startDay}, ${today.year()}`, tz);
			const end = dayjs.tz(`${theme.fields.endMonth} ${theme.fields.endDay}, ${today.year()}`, tz);
			return today.isSameOrAfter(start) && today.isSameOrBefore(end);
		});
	});
	const articlesEnabled = useIsEnabled('dei-articles', false, false);

	return (
		<>
			<Title>Diversity, Equity, and Inclusion | Troon</Title>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/dei.jpg`}
				crop="top"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>DEI</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					Diversity, Equity, and Inclusion
				</Heading>
			</Hero>

			<NoHydration>
				<Page>
					<Show when={articlesEnabled && theme()}>
						{(theme) => (
							<>
								<Container>
									<Suspense>
										<PictureSection src={theme().fields.heroImage?.fields.file?.url}>
											<div>
												<Heading as="h2">{theme().fields.title}</Heading>
												<p class="font-medium text-brand-500">
													{theme().fields.startMonth}
													<Show when={theme().fields.startMonth !== theme().fields.endMonth}>
														{' '}
														{theme().fields.startDay} – {theme().fields.endMonth} {theme().fields.endDay}
													</Show>
												</p>
											</div>
											<Show when={theme().fields.description}>{(doc) => <RichText document={doc()} />}</Show>
											<Button as={Link} href={`/about/dei/${theme().fields.slug}`} class="size-fit grow-0">
												Read more<span class="sr-only"> about {theme().fields.title}</span>
												<Icon name="arrow-right-md" />
											</Button>
										</PictureSection>
									</Suspense>
								</Container>

								<Container>
									<HorizontalRule />
								</Container>
							</>
						)}
					</Show>

					<Container size="small">
						<Page>
							<Section appearance="primary" class="items-center rounded px-4 text-center md:px-16">
								<Heading as="h2" size="h6" class="text-base uppercase">
									Mission statement
								</Heading>
								<p class="max-w-4xl text-xl font-semibold md:text-3xl">
									At Troon we are committed to building, fostering, and encouraging a culture of diversity, equity and
									inclusion that embraces the uniqueness of our communities across race, gender, age, religion,
									identity, cultures, ideas and experiences so that we may provide places of work and play in which all
									persons feel welcomed and valued.
								</p>
							</Section>

							<Section>
								<Heading as="h2">Diversity, Equity, and Inclusion Council</Heading>
								<p>
									In late 2020 Troon formed the Diversity, Equity and Inclusion Council. The Council’s goal is to help
									facilitate the following objective: Troon desires a company-wide environment in which all associates
									feel valued, included, and empowered to do their best work and bring their whole selves to work each
									and every day. Talent (including natural abilities, character and work ethic) should be the only
									meaningful differentiator at the Company. The promotion of diversity, equity and inclusion is not just
									the right thing to do, it is the smart and profitable thing to do. Diversity of thought and talent
									strengthens the Company by creating an open environment where existing associates are more productive,
									ensuring our associates are the best and brightest by accessing and recruiting from the broadest pool
									of talent, and facilitating the consideration of different perspectives when solving the challenges
									faced by the Company, its clients and its associates.
								</p>
							</Section>

							<Section>
								<Heading as="h2">Areas of focus</Heading>
								<ul class="flex flex-row flex-wrap gap-12">
									<For each={areasOfFocus}>
										{(area) => (
											<li
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="min-w-[25%] grow"
											>
												<Callout>
													<p class="text-xl font-semibold">{area}</p>
												</Callout>
											</li>
										)}
									</For>
								</ul>
							</Section>

							<Section>
								<Heading as="h2">Supplier Diversification Program</Heading>
								<p>
									As the global leader in golf and club-related leisure and hospitality services, Troon recognizes the
									importance that diversity has not only in our company and how we grow the game(s), but also how we
									support business growth and delivering value to our clients through diversity within our industry
									supply chain. We recognize how diverse perspectives can generate creative ideas, products and
									solutions that will add value to our clients while creating new revenue opportunities for both our
									clients and our trusted supplier base.
								</p>
								<p>
									To that end our Supplier Diversity program provides a pathway for diverse suppliers to add value to
									our product and service offerings. Our goal is to promote economic opportunities for historically
									disempowered groups wherever we operate.
								</p>
								<p>
									Suppliers qualify by being at least 51 percent owned by people from an ethnic minority (as defined in
									each country or region), or by women, military veterans, those with disabilities or LGBT+ individuals.
									For consideration to be added as a diverse supplier of Troon, complete the following form. Following
									your submittal, your application will be reviewed by our Director of Diversity and SVP of Procurement.
									Official responses to supplier will be made within 30 days of submittal.
								</p>
								<p>
									Thank you in advance for your interest in Troon. We take pride in seeking suppliers that are just as
									diverse as our associates, clients, members and valued guests.
								</p>
								<p>
									<Button as={Link} href="/about/dei/supplier-diversification-initiative" class="size-fit">
										Apply now
									</Button>
								</p>
							</Section>
						</Page>
					</Container>

					<Show when={articlesEnabled}>
						<Container>
							<HorizontalRule />
						</Container>

						<Container>
							<Page>
								<Section>
									<Heading as="h2" size="h3">
										Troon Honors The Diversity Of Our Associates Worldwide
									</Heading>
									<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
										<For each={themes()}>
											{(theme) => (
												<article class="group relative flex flex-col gap-2">
													<div class="overflow-hidden rounded">
														<Picture
															preload={false}
															src={theme.fields.heroImage?.fields.file?.url}
															alt={
																theme.fields.heroImage?.fields.description ?? theme.fields.heroImage?.fields.title ?? ''
															}
															sizes={[[480, 270]]}
															class="size-full group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
															crop="face"
															loading="lazy"
														/>
													</div>
													<Link
														href={`/about/dei/${theme.fields.slug}`}
														class="block text-lg font-semibold after:absolute after:inset-0"
													>
														{theme.fields.title}
													</Link>
													<p class="text-sm font-medium text-brand-500">
														{theme.fields.startMonth}
														<Show when={theme.fields.startMonth !== theme.fields.endMonth}>
															{' '}
															{theme.fields.startDay} – {theme.fields.endMonth} {theme.fields.endDay}
														</Show>
													</p>
													<Show when={theme.fields.description}>{(doc) => <RichText document={doc()} />}</Show>
												</article>
											)}
										</For>
									</div>
								</Section>
							</Page>
						</Container>
					</Show>
				</Page>
			</NoHydration>
		</>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const areasOfFocus = [
	'Communication',
	'Learning & Development',
	'Sponsorship & Partnerships',
	'Community & Engagement',
	'Employment, Recruitment, Retention',
];

const getThemes = createContentfulListRequest('dei-themes');
